
import PropTypes from "prop-types"
import React from "react"
import "../styles/global.scss"
import { useStaticQuery, graphql } from "gatsby"
import UIkit from 'uikit'
import Seo from "../components/seo/seo.js"
import FooterLanding from "../components/footer-landing/footerLanding.js"



const LandingPageLayout = ({ children, pageData, SEOTitle, SEODescription, footerDisclaimer, darkThemeNav, meta, canonical, hideQuoteButton, hidePhoneButton, overwriteQuoteButtonURL, overwritePhoneButtonNumber}) => {
    const data = useStaticQuery(graphql`
        {
            strapiSiteSettings {
                LayoutTheme
            }
        }
        `)
    return(
        <>
            {/* SEO section */}
            <Seo title={SEOTitle} description={SEODescription} meta={meta} canonical={canonical}></Seo>

            <main  className={data.strapiSiteSettings.LayoutTheme && data.strapiSiteSettings.LayoutTheme == "TI" ? "ti-layout-theme" : "landing-page-style"}>{children}</main>

            {/* footer section */}
            <FooterLanding disclaimer={footerDisclaimer}></FooterLanding> 
        </>
    )
    
}

LandingPageLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default LandingPageLayout
